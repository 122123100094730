import {environment} from '@env'
import {Injectable} from '@angular/core'
import {logger} from 'nx/src/utils/logger'
import {
  catchError,
  map,
  Observable,
} from 'rxjs'
import {EventModel, VRideModel, EventRevoke} from '@domain/ride'
import {HttpClient} from '@angular/common/http'
import {MessageService} from 'primeng/api'
import {DriverModel} from '@domain/driver'
import {DayPilot} from '@daypilot/daypilot-lite-angular'
import CalEvent = DayPilot.EventData
import {StorageService} from '@service/storage'
import Event = DayPilot.Event
import {ConfirmRideModel} from "../../../domain/src/lib/driver.model"

@Injectable({
  providedIn: 'root',
})
export class RideService {
  private rides!: VRideModel[]
  private calEvent!: CalEvent

  constructor(
      private http: HttpClient,
      private storageSvc: StorageService,
      private messageSvc: MessageService,
  ) {
  }

  getRidesByEventId(eventId: number): Observable<VRideModel[]> {
    const url = `${environment.apiUrl}/rides/events/${eventId}`
    return this.http.get<VRideModel[]>(url)
  }

  getRidesById(id: number): Observable<VRideModel[]> {
    const url = `${environment.apiUrl}/rides/${id}`
    return this.http.get<VRideModel[]>(url)
  }

  getRidesFromNow(days: number = 1): Observable<VRideModel[]> {
    logger.debug('ride-service#getRidesFromNow: days=', days)
    const url = `${environment.apiUrl}/rides/now/${days}`
    return this.http.get<VRideModel[]>(url)
  }

  getRidesPendingFromDateByDriverId(driverId: number, fromDay: string, days: number = 1): Observable<VRideModel[]> {
    logger.debug('ride-service#getRidesPendingFromDateByDriverId: driverId=', driverId, ', fromDate=', fromDay, ', days=', days)
    const url = `${environment.apiUrl}/rides/date-pending/${fromDay},${days}/driver/${driverId}`
    return this.http.get<VRideModel[]>(url)
  }

  getRidesFromDateByDriverId(driverId: number, fromDay: string, days: number = 1): Observable<VRideModel[]> {
    logger.debug('ride-service#getRidesFromDateByDriverId: driverId=', driverId, ', fromDate=', fromDay, ', days=', days)
    const url = `${environment.apiUrl}/rides/date/${fromDay},${days}/driver/${driverId}`
    return this.http.get<VRideModel[]>(url)
  }

  getRidesFromDateTimeByDriverId(driverId: number, fromDay: string, days: number = 1): Observable<VRideModel[]> {
    logger.debug('ride-service#getRidesFromDateTimeByDriverId: driverId=', driverId, ', fromDate=', fromDay, ', days=', days)
    const url = `${environment.apiUrl}/rides/datetime/${fromDay},${days}/driver/${driverId}`
    return this.http.get<VRideModel[]>(url)
  }

  getRideCountFromDay(fromDay: string, days: number = 1): Observable<number> {
    logger.debug('ride-service#getRideCountFromDay: fromDay=', fromDay, ', days=', days)
    const url = `${environment.apiUrl}/rides/date/${fromDay},${days}/count`
    return this.http.get<number>(url)
  }

  getPendingRidesCountByVehicleId(id: number): Observable<number> {
    logger.debug('ride-service#getPendingRidesCountByVehicleId: vehicleId=', id)
    const url = `${environment.apiUrl}/rides/vehicles/${id}/pending-rides-count`
    return this.http.get<number>(url)
  }

  getDriversFromDate(fromDay: string, days: number = 1): Observable<DriverModel[]> {
    const url = `${environment.apiUrl}/drivers/rides/date/${fromDay},${days}`
    logger.debug('ride-service#getDriversOfDay: url=', url)
    return this.http.get<DriverModel[]>(url)
  }

  getVRidesFromDay(fromDay: string, days: number = 1): Observable<VRideModel[]> {
    logger.debug('ride-service#getVRidesFromDate: fromDay=', fromDay, ', days=', days)
    const url = `${environment.apiUrl}/rides/date/${fromDay},${days}`
    return this.http.get<VRideModel[]>(url)
  }

  getVRidesFromDayByVehicleId(fromDay: string, days: number = 1, vehicleId: number = 0): Observable<VRideModel[]> {
    logger.debug('ride-service#getVRidesFromDayByVehicleId: fromDay=', fromDay, ', days=', days, ', vehicleId=', vehicleId)
    const url = `${environment.apiUrl}/rides/date/${fromDay},${days}/vehicle/${vehicleId}`
    return this.http.get<VRideModel[]>(url)
  }

  getVRidesFromTo(fromDay: string, toDay?: string): Observable<VRideModel[]> {
    logger.debug('ride-service#getRidesFromTo: fromDay=', fromDay, ', toDay=', toDay)
    const url = `${environment.apiUrl}/rides/date/${fromDay}/date/${toDay}`
    return this.http.get<VRideModel[]>(url)
  }

  getCalEventsFrom(fromDay: string, days: number = 1): Observable<CalEvent[]> {
    logger.debug('ride-service#getCalEventsFrom: fromDay=', fromDay, ', days=', days)
    const url = `${environment.apiUrl}/rides/calendar/events/date/${fromDay},${days}`
    return this.http.get<CalEvent[]>(url)
  }

  getCalEventsById(id: number): Observable<CalEvent[]> {
    logger.debug('ride-service#getCalEventsById: id=', id)
    const url = `${environment.apiUrl}/rides/calendar/events/${id}`
    return this.http.get<CalEvent[]>(url)
  }

  getVRides(): Observable<VRideModel[]> {
    const url = `${environment.apiUrl}/rides`
    return this.http.get<VRideModel[]>(url)
    // return this.http.get<RideModel[]>(url).pipe(
    //   tap(_ => this.log('fetched heroes')),
    //   catchError(this.handleError<RideModel[]>('getHeroes', []))
    // )
  }

  requiresTixiWheelchair(rideId: number): Observable<boolean> {
    const url = `${environment.apiUrl}/rides/${rideId}/tixi-wheelchair`
    return this.http.get<boolean>(url)
  }

  hasHandicap(rideId: number, handicap: string): Observable<boolean> {
    const url = `${environment.apiUrl}/rides/${rideId}/handicaps/${handicap}`
    return this.http.get<boolean>(url)
  }

  confirmExecutedRide(rideId: number, driverId: number): Observable<any> {
    const url = `${environment.apiUrl}/rides/confirm`
    const confirmRequest: ConfirmRideModel = {rideId, driverId}
    logger.debug('ride-service#confirmExecutedRide: confimRequest=', confirmRequest)
    return this.http.put(url, confirmRequest).pipe(
        map(value => {
          return value
        }),
    )
  }


  locateDriversDistance(rideId: number): Observable<DriverModel[]> {
    const url = `${environment.apiUrl}/rides/drivers/distance/${rideId}`
    logger.debug('ride-service#locateDriversDistance: rideId=', rideId)
    return this.http.get<DriverModel[]>(url)
  }

  /*
   async updateRide(ride: VRideModel) {
   const url = `${environment.apiUrl}/rides`
   logger.debug('ride-service#update-ride: ride=', ride)
   const response = await this.http.put(url, ride).pipe(
   catchError(this.handleError)
   ).toPromise()
   logger.debug('ride-service#update-ride: response=', response)
   return response
   }
   */

  revokeEventById(eventId: number): Observable<any> {
    const url = `${environment.apiUrl}/events/revoke`
    const request = <EventRevoke>{eventId: eventId}
    logger.debug('ride-service#revokeEventById: eventId=', eventId)
    return this.http.put(url, request).pipe(
        map(value => {
          return value
        }),
    )
  }

  updateRide(ride: VRideModel, doEditSerialRide: boolean): Observable<any> {
    const kind = doEditSerialRide ? 'serial' : 'single'
    const url = `${environment.apiUrl}/rides/${kind}`
    logger.debug('ride-service#updateRide: ride=', ride)
    return this.http.put(url, ride).pipe(
        map(value => {
          return value
        }),
    )
  }

  /*
   add(ride: RideModel, event?: EventModel): Observable<any> {
   const eventUrl = `${environment.apiUrl}/events`
   const rideUrl = `${environment.apiUrl}/rides`
   logger.debug('ride-service#add: ride=', ride, ', event=', event)
   if (event) {
   return this.http.post<number>(eventUrl, event).pipe<any>(
   switchMap(eventRes => {
   ride.eventId = eventRes
   return this.http.post(rideUrl, ride)
   }),
   )
   } else {
   return this.http.post<number>(rideUrl, ride).pipe<any>(
   map(value => {
   return value
   }),
   )
   }
   */


  /*
   addRideAndGetEvent(ride: VRideModel): Observable<any> {
   const url = `${environment.apiUrl}/rides`
   // const calEvent$= this.http.post<CalEvent[]>(url, ride)
   // const blocker$=of(true)
   return this.http.post<CalEvent[]>(url, ride).pipe(
   map(value => {
   return value
   }),
   )
   }
   */

  /*
   async addRideAndGetEvent(ride: VRideModel) {
   const url = `${environment.apiUrl}/rides`
   const response = await this.http.post<CalEvent[]>(url, ride).pipe(
   catchError(this.handleError)
   ).toPromise()
   logger.debug('ride-service#addRideAndGetEvent: response=', response)
   return response
   }
   */

  addRidesAndGetEvent(rides: VRideModel[]) {
    const url = `${environment.apiUrl}/rides`
    return this.http.post<CalEvent[]>(url, rides).pipe(
        map(value => {
          return value
        }),
    )
  }

  private handleError(error: any): Promise<any> {
    logger.debug('ride-service#handleError: error=', error)
    return Promise.reject(error.message || error)
  }


  /*
   addVRide(ride: VRideModel): Observable<any> {
   const url = `${environment.apiUrl}/rides`
   logger.debug('ride-service#add: ride=', ride)
   // if (ride.appointmentAt) {
   //   const appointmentAt = Date.parse(ride.appointmentAt)
   // }
   return this.http.post<number>(url, ride).pipe(
   map(value => {
   return value
   })
   )
   }
   */

  deleteRidesByIds(ids: number[]) {
    const url = `${environment.apiUrl}/rides/${ids}`
    logger.debug('ride-service#deleteRidesByIds: ids=', ids, 'url=', url)
    return this.http.delete(url).pipe(
        map(value => {
          return value
        }),
        // catchError(this.handleError('deleteRides')),
    )
  }

  deleteRidesByEventIds(ids: number[]) {
    const url = `${environment.apiUrl}/rides/events/${ids}`
    logger.debug('ride-service#deleteRidesByEventIds: ids=', ids, 'url=', url)
    return this.http.delete(url).pipe(
        map(value => {
          return value
        }),
    )
  }

  deleteSerialRidesByIds(ids: number[]) {
    const url = `${environment.apiUrl}/rides/${ids}/serials`
    logger.debug('ride-service#deleteSerialRidesByIds: ids=', ids, 'url=', url)
    return this.http.delete(url).pipe(
        map(value => {
          return value
        }),
        // catchError(this.handleError('deleteUser')),
    )
  }

  deleteSerialRidesByEventIds(ids: number[]) {
    const url = `${environment.apiUrl}/rides/${ids}/events/serials`
    logger.debug('ride-service#deleteSerialRidesByEventIds: ids=', ids, 'url=', url)
    return this.http.delete(url).pipe(
        map(value => {
          return value
        }),
    )
  }

  /*
   async deleteRides(ids: number[]) {
   const url = `${environment.apiUrl}/rides/${ids}`
   const response = await this.http.delete(url).pipe(
   catchError(this.handleError)
   ).toPromise()
   logger.debug('ride-service#delete: ids=', ids)
   return response
   }
   */


  getEventById(id: number): Observable<EventModel> {
    const url = `${environment.apiUrl}/events/${id}`
    return this.http.get<EventModel>(url)
  }

  /*
   getEvents(): Observable<EventModel[]> {
   const url = `${environment.apiUrl}/events`
   return this.http.get<EventModel[]>(url)
   // return this.http.get<EventModel[]>(url).pipe(
   //   tap(_ => this.log('fetched heroes')),
   //   catchError(this.handleError<EventModel[]>('getHeroes', []))
   // )
   }
   */

  /*
   updateEvent(event: EventModel): Observable<any> {
   const url = `${environment.apiUrl}/users/update`
   return this.http.put<EventModel>(url, event).pipe(
   map(value => {
   return value
   })
   )
   }
   */

  /*
   addEvent(event: EventModel): Observable<any> {
   const url = `${environment.apiUrl}/events`
   const id = this.storageSvc.getLoginUserId()
   event.createdById = id.toString()
   const res = this.http.post(url, event).pipe(
   map(value => {
   return value
   })
   // catchError(error => {
   //   logger.error(`Error: $error`)
   //   return of(null)
   // }),
   )
   logger.debug('user-service#add user: res=', res)
   return res
   // return this.http.post<UserModel>(url, user).pipe(
   // catchError(this.handleError('addUser', user)),
   // )
   }
   */

  /*
   deleteEventsByIds(ids: number[]) {
   const url = `${environment.apiUrl}/events/${ids}`
   logger.debug('ride-service#delete: ids=', ids, 'url=', url)
   return this.http.delete(url).pipe(
   // catchError(this.handleError('deleteUser')),
   )
   }
   */

  /*
   // handled by backend
   private convertDate(date:string):string{
   logger.debug('ride-service#convertDate: date=', date)
   const cdate=Date.parse(date)
   logger.debug('ride-service#convertDate: cdate=', cdate.toString())
   return cdate.toString()
   }
   */
}

/*
 getAllArray(): RideModel[] {
 of(RIDES).subscribe(response => {
 this.rides = response
 })
 return this.rides
 }

 getAllMock(): Observable<RideModel[]> {
 return of(RIDES)
 }

 private getByEventIdMock(eventId: number): Observable<RideModel[]> {
 return this.getAllMock().pipe(
 map(values => values.filter(
 value => value.eventId === eventId,
 ),
 ),
 )
 }

 private getMock(id: number) {
 return from(RIDES).pipe(
 filter(ride => ride.id === id),
 take(1),
 )
 }
 */

/**
 * Handle Http operation that failed.
 * Let the app continue.
 *
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
/*
 function handleError<T>(operation = 'operation', result?: T) {
 return (error: any): Observable<T> => {

 // TODO: send the error to remote logging infrastructure
 console.error(error) // log to console instead

 // TODO: better job of transforming error for user consumption
 log(`${operation} failed: ${error.message}`)

 // Let the app keep running by returning an empty result.
 return of(result as T)
 }
 }
 */

